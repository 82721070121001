import { useRef, useState, useEffect } from "react";
import {
  Button,
  useToast,
  Box,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { JSEncrypt } from "jsencrypt";
import CryptoJS from "crypto-js";
import axios from "axios";
import { useWeb3React } from "@web3-react/core";
import {
  setSpecFailed,
  getTransactionData,
  setSpecReviewed,
} from "../../../utils/ctf.utils";

interface TransactionData {
  address: string;
  ipfs: string;
  failed: boolean;
  score: bigint;
}

interface JsonData {
  encryptedText: string;
  encryptedAESKey: string;
}

const fetchData = async (url: string): Promise<JsonData> => {
  try {
    const response = await axios.get<JsonData>(url);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export default function DecryptSpec() {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const toast = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [privateKey, setPrivateKey] = useState<string>(
    () => localStorage.getItem("privateKey") || ""
  );
  const [tempKey, setTempKey] = useState<string>("");
  const { account, isActive, provider } = useWeb3React();

  const [transactionData, setTransactionData] = useState([]);
  const [rating, setRating] = useState({});

  const showToast = (
    title: string,
    description: string,
    status: "info" | "warning" | "success" | "error"
  ) => {
    toast({
      title,
      description,
      status,
      duration: 5000,
      isClosable: true,
    });
  };

  const handleTempKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempKey(e.target.value);
  };

  const handleSavePrivateKey = () => {
    setPrivateKey(tempKey);
    localStorage.setItem("privateKey", tempKey);
    setTempKey("");
  };

  const handleDownloadDecryptedFile = async (transaction: TransactionData) => {
    if (!privateKey) {
      showToast("Error", "Private key is required", "error");
      return;
    }

    fetchData("https://ipfs.io/ipfs/" + transaction.ipfs)
      .then((jsonData) => {
        console.log("Downloaded JSON data:", jsonData);

        setLoading(true);
        try {
          // Расшифровка AES-ключа с помощью RSA
          const decrypt = new JSEncrypt();
          decrypt.setPrivateKey(privateKey);
          const decryptedAESKey = decrypt.decrypt(jsonData.encryptedAESKey);

          if (!decryptedAESKey) {
            showToast("Error", "Failed to decrypt AES key", "error");
            setLoading(false);
            return;
          }

          // Расшифровка текста файла с помощью AES-ключа
          const decrypted = CryptoJS.AES.decrypt(
            jsonData.encryptedText,
            decryptedAESKey
          );
          const decryptedText = CryptoJS.enc.Utf8.stringify(decrypted);
          console.log("Decrypted Text:", decryptedText);

          // Создание Blob из расшифрованных данных и формирование URL для скачивания
          const decryptedBlob = new Blob([decryptedText], {
            type: "application/x-tla",
          });
          const decryptedUrl = URL.createObjectURL(decryptedBlob);
          const link = document.createElement("a");
          link.href = decryptedUrl;
          link.download = `${transaction.address}.tla`;
          link.click();

          URL.revokeObjectURL(decryptedUrl);
          showToast(
            "Success",
            "File decrypted and downloaded successfully",
            "success"
          );
        } catch (error) {
          console.error("Error while processing encrypted file:", error);
          showToast("Error", "Failed to decrypt the file", "error");
        } finally {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Failed to download JSON data:", error);
        showToast("Error", "Failed to load a file from IPFS", "error");
      });
  };

  const handleSetSpecFailed = async (transaction: TransactionData) => {
    await setSpecFailed(provider, transaction.address, transaction.ipfs);
  };

  const handleGetData = async () => {
    setLoading(true);
    try {
      const data = await getTransactionData(provider);
      setTransactionData(data);
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRatingChange = (index, value) => {
    const newRating = Math.max(0, Math.min(10, Number(value)));
    setRating((prev) => ({
      ...prev,
      [index]: newRating,
    }));
  };

  const handleSendRating = async (transaction, score) => {
    if (!isNaN(score)) {
      setLoading(true);
      try {
        // Вызов контракта с адресом транзакции и оценкой
        await setSpecReviewed(
          provider,
          transaction.address,
          transaction.ipfs,
          score
        );
        console.log(
          `Оценка ${score} отправлена для транзакции ${transaction.address}`
        );
      } catch (error) {
        console.error("Ошибка при отправке оценки: ", error);
      } finally {
        setLoading(false);
      }
    } else {
      console.error("Введено некорректное значение");
    }
  };

  return (
    <Box>
      <Input
        placeholder="Enter your private key"
        value={tempKey}
        onChange={handleTempKeyChange}
        mb={4}
      />
      <Button colorScheme="red" onClick={handleSavePrivateKey} mb={4}>
        Save Key
      </Button>
      <br></br>
      <Button colorScheme="red" onClick={handleGetData} mb={4}>
        Get Data
      </Button>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Address</Th>
            <Th>Download</Th>
            <Th>Review</Th>
            <Th>Fail</Th>
            <Th>Failed</Th>
            <Th>Reviewed</Th>
          </Tr>
        </Thead>
        <Tbody>
          {transactionData.map((transaction, index) => (
            <Tr key={index}>
              <Td>{transaction.address}</Td>
              <Td>
                <Button
                  colorScheme="red"
                  onClick={() => handleDownloadDecryptedFile(transaction)}
                  isLoading={loading}
                >
                  Download Decrypted Specification
                </Button>
              </Td>
              <Td>
                <Input
                  type="number"
                  placeholder="0-10"
                  onChange={(e) => handleRatingChange(index, e.target.value)} // Сохраняем значение в локальной переменной
                  max={10}
                  min={0}
                  width="70px"
                  mb={2}
                />
                <Button
                  colorScheme="red"
                  onClick={() => handleSendRating(transaction, rating[index])} // Передаём значение при клике
                  isLoading={loading}
                >
                  Submit Score
                </Button>
              </Td>

              <Td>
                <Button
                  colorScheme="red"
                  onClick={() => handleSetSpecFailed(transaction)}
                  isLoading={loading}
                >
                  Set Spec Failed
                </Button>
              </Td>
              <Td>{transaction.failed ? "Failed" : ""}</Td>
              <Td>{Number(transaction.score)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
}
