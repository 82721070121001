import {
  Box,
  Container,
  Heading,
  Stack,
  TableContainer,
  Text,
  Tfoot,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import { useWeb3React } from "@web3-react/core";
import {
  getLeaderboardPssv2024,
  LeaderboardPssv2024,
} from "../../../utils/ctf.utils";

export default function Innopolis2024() {
  const { account, isActive, provider } = useWeb3React();
  const [leaderboardPssv2024, setLeaderboardPssv2024] = useState<
    LeaderboardPssv2024[]
  >([]);

  useEffect(() => {
    const fetchData = async () => {
      const leaderboard = await getLeaderboardPssv2024(provider);
      console.log(leaderboard);
      setLeaderboardPssv2024(leaderboard);
    };

    fetchData();
  }, [isActive, account, provider]);

  return (
    <Container maxW={"7xl"}>
      <Stack
        as={Box}
        textAlign={"center"}
        spacing={{ base: 6, md: 12 }}
        py={{ base: 18, md: 18 }}
      >
        <Heading lineHeight={"110%"}>
          <br />
          <Text color={"red"}>Innopolis 2024</Text>
        </Heading>
        {/* <CustomDivider title="Leaderboard" /> */}

        <TableContainer>
          <Table variant="simple">
            {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
            <Thead>
              <Tr>
                <Th>position</Th>
                <Th>address</Th>
                <Th>points</Th>
              </Tr>
            </Thead>

            {leaderboardPssv2024.length === 0 ? (
              <Tbody>
                <Tr key={0}>
                  <Td>{leaderboardPssv2024.length + 1}</Td>
                  <Td>none</Td>
                  <Td>none</Td>
                </Tr>
              </Tbody>
            ) : (
              <Tbody>
                {leaderboardPssv2024.map((user, index) => (
                  <Tr key={index}>
                    <Td>{index + 1}</Td>
                    <Td>{user.address}</Td>
                    <Td>{Number(user.score)}</Td>
                  </Tr>
                ))}
              </Tbody>
            )}
            <Tfoot>
              <Tr>
                <Th>position</Th>
                <Th>address</Th>
                <Th>points</Th>
              </Tr>
            </Tfoot>
          </Table>
        </TableContainer>
      </Stack>
    </Container>
  );
}
