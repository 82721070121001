export const publicKey = `
-----BEGIN RSA PUBLIC KEY-----
MIIBCgKCAQEApffIkMCKM/Cq8gozLMkjjjjql/F0vTbfAD/kpAOjwRjtgsqe8/F9
A+fvLRWWUFQVlLrfzX6pBxq9G+mnw+xM3GlAyT6gUjQD1yVHSRKk90d+VrJwB3W/
Y6HejvOV0PLeDUb2W9B0UtHWOSOiVbm5CBat9TqMTa12B3golXIN8urDmEPiXHGI
pHmKIDLU5vHojnMguCmkx4S8GPfAGFa8aw4Xlpj+/A554JYeVhctwCQwRs+YccJA
ZrC/cS3MoBVHaxv0sskNyu3EMdJCeGwX0KBD3ZuYEbjHIQfP3fxq2cRWAmZbyImv
XEa0YCNF3z1GmsTJR9qQwuai5S1m6x27RwIDAQAB
-----END RSA PUBLIC KEY-----
`;

export const api =
  "https://dev1-ctf-back.k8s-staging.ptsecurity.com/api/v1/fm/solution";

export const publicApi = "https://web3.ptsecurity.com/api/v1/fm/solution";
