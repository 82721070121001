import {
  Container,
  useToast,
  Text,
  HStack,
  Input,
  Stack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { useLocation, useParams } from "react-router-dom";
import addresses from "../../db/ctf/ctf_address.json";
import { useTranslation } from "react-i18next";
import TonWeb from "tonweb";
import { Cell } from "tonweb/dist/types/boc/cell";

export default function TONStorageFeeCalc() {
  const { t } = useTranslation();
  const { account, isActive, provider } = useWeb3React();
  const location = useLocation();

  const [storageFee, setStorageFee] = useState<string>("");
  const [codeHex, setCodeHex] = useState<string>("");
  const [dataHex, setDataHex] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
  }, []);

  const calculateStorageCell = (cell: Cell, acc: number): number => {
    let count = acc + cell.bits.length;
    for (let index = 0; index < cell.refs.length; index++) {
      count = calculateStorageCell(cell.refs[index], count);
    }
    return count;
  };

  const base64ToHex = (base64: string): string => {
    const raw = atob(base64);
    let result = '';
    for (let i = 0; i < raw.length; i++) {
      const hex = raw.charCodeAt(i).toString(16);
      result += (hex.length === 2 ? hex : '0' + hex);
    }
    return result.toUpperCase();
  }

  const handleStorageCalc = (address: string | any[]) => {
    // if (!(isActive && account && provider)) return;

    setLoading(true);

    const tonweb = new TonWeb(new TonWeb.HttpProvider('https://toncenter.com/api/v2/jsonRPC', { apiKey: '' }));

    tonweb.provider.getAddressInfo(address).then((result: any) => {
      setCodeHex(base64ToHex(result.code));
      setDataHex(base64ToHex(result.data));
    }).catch((error: any) => {
      console.log("result error: ", error);
    });

    try {

      let codeCell = TonWeb.boc.Cell.fromBoc(codeHex);
      let dataCell = TonWeb.boc.Cell.fromBoc(dataHex);

      let codeBits = calculateStorageCell(codeCell[0], 0);
      let dataBits = calculateStorageCell(dataCell[0], 0);

      console.log("all bits: ", codeBits + dataBits);

      const size = codeBits + dataBits;
      const duration = 60 * 60 * 24 * 365 // 1 Year in secs

      const bit_price_ps = 1
      const cell_price_ps = 500

      const pricePerSec = size * bit_price_ps +
        + Math.ceil(size / 1023) * cell_price_ps

      let fee = Math.ceil(pricePerSec * duration / 2 ** 16) * 10 ** -9
      let mb = (size / 1024 / 1024 / 8);
      let days = Math.floor(duration / (3600 * 24))

      let str = `Storage Fee: ${fee} TON (${mb} MB for ${days} days)`

      setStorageFee(str);
      setLoading(false);

    } catch (error) {
      console.error("Error: ", error);
      setLoading(false);
    }

  };

  return (
    <Container maxW={"7xl"}>
      <Stack gap="10" width="full">
        <Input placeholder="Address..." onChange={(e) => handleStorageCalc(e.target.value)} />
        <Text>{storageFee}</Text>
      </Stack>
    </Container>
  );
}
