import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Container,
  Grid,
  GridItem,
  Stack,
  Spinner,
  Center,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { useLocation, useParams } from "react-router-dom";
import addresses from "../../db/ctf/ctf_address.json";
import { useTranslation } from "react-i18next";
import {
  ctfStarted,
  getTotalNoOfLevelInstancesCreatedByPlayer,
  getTotalNoOfLevelInstancesCompletedByPlayer,
  createLevelInstance,
  submitLevelInstance,
  getCurrentLevelInstance,
  getTotalNoOfFailedSubmissionsByPlayer,
  isRegisted,
  isLevelCompleted,
  joinToFormalChallange,
  joinedToFormal,
} from "../../utils/ctf.utils";
import challenges from "../../db/ctf/challenges.json";
import ChallengeCode from "../CTF/ChallengeCode";
import ChallengeDescription from "../CTF/ChallengeDescription";
import ChallengeNavigation from "../CTF/ChallengeNavigation";
import CircomCode from "../CTF/CircomCode";
import CopiableInstance from "../CTF/CopiableInstance";
import CreateProof from "../CTF/CreateProof";
import { CustomDivider } from "../CustomDivider/CustomDivider";
import TONStorageFeeCalc from "./TONStorageFeeCalc";

export default function ToolsPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { account, isActive, provider } = useWeb3React();
  const location = useLocation();

  const [challengeAddress, setChallengeAddress] = useState<string>("");
  const [started, setStarted] = useState<boolean>(false);
  const [instanceCreated, setInstanceCreated] = useState<number>(0);
  const [instanceCompleted, setInstanceCompleted] = useState<number>(0);
  const [instanceFailed, setInstanceFailed] = useState<number>(0);
  const [currentInstance, setCurrentInstance] = useState<string>("");
  const [registed, setRegisted] = useState<boolean>(false);
  const [challangeType, setChallangeType] = useState<string>("");
  const [levelCompleted, setLevelCompleted] = useState<boolean>(false);
  const [isJoned, setJoinToFormal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [levelDifficult, setLevelDifficult] = useState<number>(0);
  const [levelCategory, setLevelCategory] = useState<string>("");
  // const { addresses, setAddresses } = useContext(AddressContext);
  const toast = useToast();

  useEffect(() => {

    const updateChallengeAddress = () => {
      if (!(isActive && account && provider)) return;
    };

    const fetchData = async () => {
      if (!(isActive && account && provider)) return;

      try {
        const code = await provider.getCode(addresses.positiveCTF);
        if (code === "0x") return;

      } catch (error) {
        console.error("Error: ", error);
      }
    };

    updateChallengeAddress();
    fetchData();
  }, [
    location.pathname,
    isActive,
    account,
    provider,
    challengeAddress,
    loading,
  ]);

  const showToast = (title, description, status) => {
    toast({
      title,
      description,
      status,
      duration: 10000,
      isClosable: true,
    });
  };

  const handleCreateLevelInstance = () => {
    if (!(isActive && account && provider)) return;

    setLoading(true);

    try {
    } catch (error) {
    }
  };

  const handleSolve = () => {
    if (!(isActive && account && provider)) return;

    setLoading(true);

    try {
    } catch (error) {
      console.error("Error: ", error);
      setLoading(false);
    }
  };

  return (
    <Container maxW={"7xl"}>
      <Grid
        // templateRows="repeat(2, 1fr)"
        templateColumns="repeat(5, 1fr)"
        gap={4}
      >
        <GridItem colSpan={1}>
          <CustomDivider title={t("toolkit")} padding="5" />

          <p>
            {t("Calc TON Storage fee")}
          </p>


        </GridItem>
        <GridItem colSpan={4}>
          <CustomDivider title={t("Tool")} padding="5" />
          <TONStorageFeeCalc />

        </GridItem>
      </Grid>
    </Container>
  );
}
