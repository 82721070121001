import { Button, Center, Grid } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import challenges from "../../db/ctf/challenges.json";

export default function ChallengeNavigation() {
  const location = useLocation();
  const navigate = useNavigate();
  const [routes, setRoutes] = useState<string[]>([]);

  useEffect(() => {
    setRoutes(getRoutes());
  }, []);

  const getRoutes = () => {
    let routes: string[] = [];
    challenges.challenges.forEach((challenge) => {
      routes.push("/ctf/" + challenge.name);
    });

    return routes;
  };

  const getNext = () => {
    const currentIndex = routes.indexOf(location.pathname);
    if (currentIndex === -1 || currentIndex === routes.length - 1) {
      navigate(routes[0]); // Возвращаемся к первому маршруту, если текущий последний
    } else {
      navigate(routes[currentIndex + 1]); // Переходим на следующий маршрут
    }
  };

  const getPrevious = () => {
    const currentIndex = routes.indexOf(location.pathname);
    if (currentIndex === -1 || currentIndex === 0) {
      navigate(routes[routes.length - 1]); // Переходим на последний маршрут, если текущий первый
    } else {
      navigate(routes[currentIndex - 1]); // Переходим на предыдущий маршрут
    }
  };

  return (
    <Grid>
      <Center>
        <Button
          colorScheme="red"
          onClick={getPrevious}
          variant="outline"
          w="120px"
          mx="25px"
        >
          Prev
        </Button>
        <Button
          colorScheme="red"
          onClick={getNext}
          variant="outline"
          w="120px"
          mx="25px"
        >
          Next
        </Button>
      </Center>
    </Grid>
  );
}
