import { useRef, useState } from "react";
import {
  Button,
  useToast,
  Box,
  Center,
  Spinner,
  Input,
} from "@chakra-ui/react";
import { JSEncrypt } from "jsencrypt";
import CryptoJS from "crypto-js";
import axios from "axios";
import { publicKey, api } from "./TempKeys";
import { CustomDivider } from "../../CustomDivider/CustomDivider";

export default function FMSpec() {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const toast = useToast();
  const [loading, setLoading] = useState<boolean>(false);

  const showToast = (
    title: string,
    description: string,
    status: "info" | "warning" | "success" | "error"
  ) => {
    toast({
      title,
      description,
      status,
      duration: 5000,
      isClosable: true,
    });
  };

  const generateAESKey = () => CryptoJS.lib.WordArray.random(16).toString();

  const handleFileUploadAndEncrypt = async () => {
    fileInputRef.current?.click();
  };

  const processFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) {
      showToast("Error", "Please upload a valid .tla file", "error");
      return;
    }

    setLoading(true);
    try {
      const reader = new FileReader();
      reader.onload = async function (event) {
        const arrayBuffer = event.target?.result as ArrayBuffer;

        const aesKey = generateAESKey();
        const wordArray = CryptoJS.lib.WordArray.create(arrayBuffer);
        const encryptedText = CryptoJS.AES.encrypt(
          wordArray,
          aesKey
        ).toString();

        const encrypt = new JSEncrypt();
        encrypt.setPublicKey(publicKey);
        const encryptedAESKey = encrypt.encrypt(aesKey);

        const formData = new FormData();

        const solutionKey = "solution.tla";

        formData.append(
          solutionKey,
          new Blob([JSON.stringify({ encryptedText, encryptedAESKey })], {
            type: "application/json",
          }),
          `${file.name}.json`
        );

        Array.from(formData.keys()).forEach((key) => {
          console.log(`${key}:`, formData.get(key));
        });

        formData.forEach((value, key) => {
          if (value instanceof Blob) {
            const reader = new FileReader();
            reader.onload = () => {
              const jsonContent = JSON.parse(reader.result as string); // Парсим JSON
              console.log(`${key}:`, JSON.stringify(jsonContent, null, 2)); // Читаемый JSON с отступами
            };
            reader.readAsText(value); // Чтение содержимого Blob как текст
          } else {
            console.log(`${key}:`, value);
          }
        });

        const response = await axios.post(api, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        console.log("RESULT:", response);
        console.log("IPFS", response.data);
        showToast("Success", "Encrypted file sent successfully", "success");
      };
      reader.readAsArrayBuffer(file);
    } catch (error) {
      console.error("Error while processing file:", error);
      showToast("Error", "Failed to encrypt and send the file", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box maxWidth="800px" mx="auto" p={4}>
      <CustomDivider title={"Your spec"} padding="5" />

      {!loading ? (
        <>
          <Button colorScheme="red" onClick={handleFileUploadAndEncrypt}>
            Upload and Send Encrypted File
          </Button>
          <Input
            type="file"
            ref={fileInputRef}
            onChange={processFile}
            accept=".tla"
            style={{ display: "none" }}
          />
        </>
      ) : (
        <Center>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Center>
      )}
    </Box>
  );
}
