import {
  Box,
  Container,
  Heading,
  Stack,
  TableContainer,
  Text,
  Tfoot,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import { useWeb3React } from "@web3-react/core";
import { getAttempts, Attempts } from "../../../utils/ctf.utils";
import { CustomDivider } from "../../CustomDivider/CustomDivider";

export default function Answers() {
  const { account, isActive, provider } = useWeb3React();
  const [attempts, setAttempts] = useState<Attempts[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const attempts_temp = await getAttempts(provider);
      console.log(attempts_temp);
      setAttempts(attempts_temp);
    };

    fetchData();
  }, [isActive, account, provider]);

  return (
    <Container maxW={"7xl"}>
      <Stack
        as={Box}
        textAlign={"center"}
        spacing={{ base: 6, md: 12 }}
        py={{ base: 18, md: 18 }}
      >
        <CustomDivider title="Attempts" />

        <TableContainer>
          <Table variant="simple">
            {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
            <Thead>
              <Tr>
                <Th>Attempt</Th>
                <Th>Points</Th>
              </Tr>
            </Thead>

            {attempts.length === 0 ? (
              <></>
            ) : (
              <Tbody>
                {attempts.map((user, index) => (
                  <Tr key={index}>
                    <Td>{index + 1}</Td>

                    <Td>
                      {user.score === -1
                        ? user.failed
                          ? "Failed"
                          : "On the review"
                        : user.score}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            )}
            <Tfoot>
              <Tr>
                <Th>Attempt</Th>
                <Th>Points</Th>
              </Tr>
            </Tfoot>
          </Table>
        </TableContainer>
      </Stack>
    </Container>
  );
}
